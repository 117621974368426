import React from "react";
import "./css/index.css";
import { BlockEntry } from "./blockEntry";
import { setupTwind } from "@blocksui/core";
import { tailwindConfig } from "./tailwindConfig";

setupTwind(tailwindConfig);

function App() {
  return <BlockEntry />;
}

export default App;
