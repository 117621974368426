export const tailwindConfig = {
  theme: {
    extend: {
      backgroundImage: {
        banner: 'url("assets/banner.svg")',
        "banner-sm": 'url("assets/banner_mobile.svg")',
      },
      colors: {
        blue: {
          500: "#4698DB",
          800: "#2F6EAD",
          900: "#0C1D2C",
        },
        orange: {
          500: "#FBBF24",
        },
      },
      border: {
        3: "3px",
      },
      maxHeight: {
        "200px": "200px",
        "300px": "300px",
        "350px": "350px",
        "400px": "400px",
      },
      maxWidth: {
        "200px": "200px",
        "300px": "300px",
        "350px": "350px",
        "400px": "400px",
      },
      height: {
        "500px": "500px",
        "600px": "600px",
        "800px": "800px",
        "900px": "900px",
        "1/3": "33%",
        "1/2": "50%",
      },
      width: {
        "200px": "200px",
        "300px": "300px",
        "350px": "350px",
        "400px": "400px",
      },
      fontFamily: {
        sans: ["Futura", "sans-serif"],
      },
    },
  },
};
