import { BlocksUI, CustomBlockContext, IBlock } from "@blocksui/core";
import { GanttChart } from "@blocksui/charts";
import React, { useCallback, useEffect, useState } from "react";

export const BlockEntry: React.FC<{}> = () => {
  const [block, setBlock] = useState<IBlock | null>();

  const loadBlock = useCallback(async () => {
    let screenSize = "xl";
    if (window.innerWidth < 640) {
      screenSize = "xs";
    } else if (window.innerWidth < 768) {
      screenSize = "sm";
    } else if (window.innerWidth < 1024) {
      screenSize = "md";
    } else if (window.innerWidth < 1280) {
      screenSize = "lg";
    }

    const result = await fetch("/blocks/loadEntry", {
      headers: {
        ss: screenSize,
      },
    });
    return await result.json();
  }, []);

  useEffect(() => {
    (async () => {
      const b = await loadBlock();
      setBlock(b);
    })();
  }, [loadBlock]);

  if (!block) {
    return null;
  }

  return (
    <CustomBlockContext.Provider
      value={{
        renderers: {
          GanttChart,
        },
      }}
    >
      <BlocksUI block={block as any} baseUrl={"/"}></BlocksUI>
    </CustomBlockContext.Provider>
  );
};
